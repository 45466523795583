import {
  Hero,
  AgeDisc,
  GamesList,
  Contacts,
  Description,
  Benefits,
  NoMoney,
  CookieModal,
} from "./components";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import DisclaimerModal from "./components/DisclaimerSection";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Hero />
        <Benefits />
        <GamesList />
        <Contacts />
        <DisclaimerModal />
        <AgeDisc />
        <CookieModal />
      </ThemeProvider>
    </div>
  );
}

export default App;
