import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const DisclaimerSection = () => {
  return (
    <div className="disclaimer">
      <Container>
      <Typography variant="h3">Disclaimer</Typography>
      <Typography variant="body1" paragraph>
      Welcome to our online social games website. Before using this site and/or any of the games
      (collectively, the "Services") provided by us, please read and agree to the terms and
      conditions set forth below:
    </Typography>
    <Typography variant="h6">General Information:</Typography>
    <Typography>
      We are not a gambling operator, and the Services are not intended for real-money gambling purposes.
    </Typography>
    <Typography>
      The Services offer virtual pokies-style entertainment games and do not involve any real money bets or prizes.
    </Typography>
    <Typography>
      We do not collect any personal information from you except for what is necessary to provide you with the Services. Any personal information you provide to us will be treated in accordance with our privacy policy.
    </Typography>
    <Typography>
      Additionally, it's important to understand that while we provide a platform for entertainment, we comply fully with the United Kingdom's gambling laws. Our Services are designed with a strict adherence to the UK Gambling Commission's guidelines for social gaming and do not offer "real money gambling" or an opportunity to win real money or prizes based on the outcome of play.
    </Typography>
    <Typography>
      Under UK law, our virtual games are classified as entertainment products and not as gambling. We ensure that all our games meet the legal requirements set by the Gambling Act 2005 and subsequent amendments, providing a safe and legal alternative for those who enjoy gaming without the risks associated with gambling.
    </Typography>
    <Typography>
      We are committed to promoting responsible gaming and encourage our users to play responsibly. Please enjoy our Services in a responsible manner.
    </Typography>
     
      </Container>
    </div>
  );
};

export default DisclaimerSection;
