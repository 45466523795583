// src/components/CTA.js
import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {bg} from "../assets";
import Grow from '@mui/material/Grow';
import { Link } from 'react-router-dom';

function CTA() {
  return (
    <div style={{background: "#006D77"}}>
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row' }} 
        py={4}
        gap="2rem"
      >
        {/* Image Block */}
        <img
          src={bg}
          alt="CTA"
          style={{ maxWidth: '400px', width: "100%", height: 'auto' }}
        />

        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
        <Box mt={3}>
          <Typography variant="h4" gutterBottom>
          The Most Reliable Platform for Enjoying Free Games
          </Typography>
          <Typography variant="body1" paragraph>
          Explore our vast selection of slot games tailored to suit all player levels. Whether you're a seasoned gamer or just starting out, you'll discover something that matches your preferences. Our game collection spans from timeless classics to contemporary video slots, all boasting captivating graphics and immersive gameplay to ensure your entertainment.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/about"
            
          >
            About us
          </Button>
        </Box>
          </Grow>
      </Box>
    </Container>
    </div>
  );
}

export default CTA;
