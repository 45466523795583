// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg, bg } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{
        background: `linear-gradient(170deg, rgba(0,0,0,.7) 0%, rgba(0, 0, 0,.7) 70%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <Header />
      <Container maxWidth="lg">
        <Grow in={true} timeout={1200}>
          <Box px={3} textAlign="center">
            <Typography variant="h4" color="primary.main50">
              Welcome to Prismopath
            </Typography>
            <Typography
              variant="h1"
              fontSize={{ xs: "4rem", md: "6rem" }}
              color="white"
              gutterBottom
              style={{ fontWeight: "900" }}
            >
              Your Ultimate Gaming Destination
            </Typography>
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default Hero;
