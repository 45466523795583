import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Grow from "@mui/material/Grow";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
  },
  paperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    textAlign: "center",
    color: "#ffffff",
  },
  paper: {
    padding: "2rem 1.5rem",
    borderRadius: "30px!important",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white!important",
    backgroundColor: "#004F5D!important",
  },
}));

// ... (previous code)

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg" className={classes.container} id="about">
      <Typography variant="h4" gutterBottom textAlign="center">
        At Prismopath, we're not just about games; we're about creating
        unforgettable moments and fostering a community where joy knows no
        bounds. Our journey began with a single mission: to unlock the limitless
        potential of games to bring people together, spark creativity, and
        spread happiness far and wide.
      </Typography>
      <Typography variant="h6" gutterBottom marginTop="1rem">
        With a passion for innovation and an unwavering commitment to our
        community, we've built a platform that stands at the intersection of
        technology, art, and human connection. Our curated collection of games
        spans across genres, offering something special for everyone — from the
        adrenaline-pumping action for thrill-seekers to the calming puzzles for
        those seeking a peaceful escape.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Prismopath is more than just a gaming website; it's a portal to new
        worlds, a place where you can challenge your limits, meet like-minded
        adventurers, and experience the joy of gaming in its purest form.
        Whether you're here to explore new territories, conquer epic challenges,
        or simply enjoy a casual game after a long day, Prismopath is your go-to
        source for cost-free fun, ease of use, and an ever-expanding universe of
        games.
      </Typography>
      <Typography variant="h4" gutterBottom textAlign="center" marginTop="2rem">
        Why Choose Prismopath?
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
        marginTop="1rem"
      >
        <Grow in={true} timeout={1000} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">Unlimited Diversity</Typography>
              <Typography variant="body1">
                Immerse yourself in our vast library of games, where endless
                choices ensure your gaming experience is always fresh and
                exciting.
              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1400} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">Designed for Gamers</Typography>
              <Typography variant="body1">
                Our user-friendly platform makes it a breeze to discover new
                games, with intuitive navigation and personalized
                recommendations tailored to your tastes.
              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1600} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">Free Access, Forever</Typography>
              <Typography variant="body1">
                Enjoy full access to our gaming world without the worry of
                hidden costs or subscriptions. At Prismopath, fun comes free.
              </Typography>
            </Paper>
          </Grid>
        </Grow>
      </Grid>
    </Container>
  );
}
