import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
}));

function CasinoRules() {
  const classes = useStyles();

  return (
      <Container>
        <Box className="description">
          <Typography variant="h4" className={classes.header}>
            Key Insights About Our Website
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Age Requirement:</b> Players must be 18 years of age or older to
            participate in any games at The Magic Social pokies.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Entertainment Only:</b> The Magic Social pokies is designed for
            entertainment purposes only. Real money winnings are not possible.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Account Sharing:</b> Sharing of player accounts with others is strictly
            prohibited.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Accurate Information:</b> Players are required to provide accurate
            personal information during the registration process.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Cheating and Hacking:</b> Any attempts to cheat or hack the games will
            result in a permanent ban.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Account Termination:</b> The Magic Social pokies reserves the right to
            suspend or terminate accounts of players who violate the rules.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Terms and Conditions:</b> Players must adhere to the terms and
            conditions of the site.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Account Responsibility:</b> Players are solely responsible for any
            actions taken on their account.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Respectful Behavior:</b> Using abusive language or harassing other
            players is strictly prohibited.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Impersonation:</b> Impersonating others or creating multiple accounts is
            not allowed.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Advertising Policy:</b> Advertising non-Magic Social pokies products or
            URLs on the site is prohibited.
          </Typography>
          <Typography variant="body1" className={classes.rule}>
            <b>Permission for Promotion:</b> Players must obtain permission from The
            Magic Social pokies before engaging in any form of advertising or
            promotion of the site.
          </Typography>
        </Box>
      </Container>
  );
}

export default CasinoRules;
