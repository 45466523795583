import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#0A2A2B',
      main: '#006D77',
      main600: '#005B69',
      main500: '#004F5D',
      main400: '#006D77',
      main300: '#4C9A9A',
      main200: '#62B5B5',
      main100: '#88CCCC',
      main50: '#B2E2E2',
    },
    secondary: {
      main: '#EDB426', 
      secondary: '#FF9F29'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Rowdies, sans-serif', 
    color: "white",
    h3: {
      fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
  }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
